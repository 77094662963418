import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

import '../styles/services.scss'

import { responsiveTitle1 } from '../components/typography.module.scss'
import SessionBanner from '../components/session-banner'

export const query = graphql`
  query ServicePageQuery {
    galleryImage: allSanityGalleryImage{
      edges{
        node{
          id,
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          },
          slideshow{
            slides{
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
            }
          },
          categories{
            title
          }
        }
      }
    }
  }
`


const ServicesPage = props => {
   const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const imageGalleryNodes =
    data && data.galleryImage && mapEdgesToNodes(data.galleryImage)
  return (
    <Layout>
      <SEO title='Services' />
      {imageGalleryNodes.map((node,index) => (
          <div className='img' key={index}>
            {/* <img src={data.galleryImage.group[index].fieldValue.match(regex)}/> */}
            {console.log(node.slideshow)}
          </div>
        ))}
      <section id='workshops'>
        <Container>
          <h2>Education & Workshop Examples</h2>
          <div className='row'>
            <div className='col-md'>
              <div className='card order-1'>
                <h4>Increase Profitability</h4>
                <ul>
                  <li>Frame styling techniques</li>
                  <li>Improving multiple pair sales - sunwear and task specific lenses</li>
                  <li>Visual merchandising in your office - Set the scene for increased eyewear sales</li>
                  <li>Frame inventory management</li>
                  <li>Analyze and create a price list</li>
                </ul>
              </div>
              <div className='card order-4'>
                <h4>Improve Lensometry Skills</h4>
                <ul>
                  <li>Training on automated and manual lensmeters</li>
                  <li>Accurate reading of progressives and prism Rx's</li>
                  <li>Correct neutralization of unknown patient prescriptions</li>
                  <li>Efficient check in of laboratory prescriptions</li>
                </ul>
              </div>
            </div>
            <div className='col-md'>
              <div className='card order-2'>
                <h4>Elevate Patient Experience</h4>
                <ul>
                  <li>Analyze patient flow and touch points</li>
                  <li>Understanding and choosing task specific lenses, near or intermediate</li>
                  <li>Understanding the newest lenses and practicing effective troubleshooting</li>
                </ul>
              </div>
              <div className='card order-7'>
                <h4>National & International Speaking Engagements</h4>
                <ul>
                  <li>American Academy of Optometry, Annual Meeting</li>
                  <li>Pacific College of Optometry, Island Eyes Conference</li>
                  <li>University of California Berkeley School of Optometry, Alumni Reunion</li>
                  <li>UC Berkeley School of Optometry, Staff & Faculty Continuing Education</li>
                  <li>Vision Source, The Exchange</li>
                </ul>
              </div>
            </div>
            <div className='col-md'>
              <div className='card order-3'>
                <h4>Cost Reduction Projects</h4>
                <ul>
                  <li>Reducing Lab Redos </li>
                  <li>Working with your Lab - Tracking Redos</li>
                  <li>Returns - understanding your suppliers’ warranties</li>
                  <li>Managing your dispensary flow</li>
                </ul>
              </div>
              <div className='card order-5'>
                <h4>Build a Strong Team</h4>
                <ul>
                  <li>Team building excercises</li>
                  <li>Collaboration and communication training</li>
                  <li>Structuring efficient staff meetings</li>
                </ul>
              </div>
              <div className='card order-6'>
                <h4>Doctor and Office Manager Leadership Development </h4>
                <ul>
                  <li>How to run an efficient staff meeting</li>
                  <li>Creating an office procedure manual</li>
                  <li>Managing staff to increase morale, loyalty and high productivity</li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <SessionBanner />
    </Layout>
  )
}

export default ServicesPage
